import React from 'react'
import './Archive.scss'
import ProjectListing from '../../components/Projects/ProjectListing'
function Archive() {
  return (
    <div>
      <ProjectListing />
    </div>
  )
}

export default Archive