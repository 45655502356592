import React from 'react';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';
import PrimaryHeader from './public/components/PrimaryHeader/PrimaryHeader';
import PrimaryFooter from './public/components/PrimaryFooter/PrimaryFooter';
//import Pages
import Home from './public/pages/HomePage/Home';
import AboutUs from './public/pages/AboutUs/AboutUs';
import Archive from './public/pages/OurCaseStudies/Archive';

function App() {
  return (
    <div className="App">
      <Router>
        <PrimaryHeader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/projects/" element={<Archive />} />
        </Routes>
        <PrimaryFooter />
      </Router>
    </div>
  );
}

export default App;
