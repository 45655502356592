// OffCanvas.js

import React from 'react';
import './OffCanvas.scss';
import { Link } from 'react-router-dom';
import { CloseCircle } from 'iconsax-react';
import LogoWhite from '../../assets/images/LogoWhite.png';

const OffCanvas = ({ isActive, handleClose }) => {
  return (
    <div className={`off-canvas-menu ${isActive ? 'active' : ''}`}>
      <div className="off-canvas-header">
        <div className='logo-conainer'>
            <img src={LogoWhite} width={200} alt="logo" />
        </div>
        <button className="close-btn" onClick={handleClose}>
          <CloseCircle size="32" color="#fff" variant="TwoTone" />
        </button>
      </div>
      <div className="off-canvas-body">
        <ul className="off-canvas-items">
          <li className="off-canvas-item">
            <Link to="/" onClick={handleClose}>Home</Link>
          </li>
          <li className="off-canvas-item">
            <Link to="/about-us" onClick={handleClose}>About Us</Link>
          </li>
          <li className="off-canvas-item">
            <Link to="/services" onClick={handleClose}>Our Services</Link>
          </li>
          <li className="off-canvas-item">
            <Link to="/projects" onClick={handleClose}>Our Case Studies</Link>
          </li>
          <li className="off-canvas-item">
            <Link to="/contact-us" onClick={handleClose}>Contact Us</Link>
          </li>
        </ul>
        <div className='action-buttons'>
          <button id="PrimaryButton" to="tel:+355692225810">Call Us</button>
          <button id="SecondaryButton" to="https://wa.me/+355692225810">Whatsapp Us</button>
        </div>
      </div>
    </div>
  );
};

export default OffCanvas;
