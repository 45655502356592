import React, { useState } from 'react';
import './PrimaryHeader.scss';
import LogoDark from '../../assets/images/LogoDark.png';
import { Link } from 'react-router-dom';
import { SidebarLeft } from 'iconsax-react';
import OffCanvas from './OffCanvas';

function PrimaryHeader() {
  const [offCanvasActive, setOffCanvasActive] = useState(false);

  const handleClick = () => {
    setOffCanvasActive(!offCanvasActive);
  };

  const handleClose = () => {
    setOffCanvasActive(false);
  };

  return (
    <>
      <div className='container-wrapper'>
        <div className='inner-container primary-header'>
          <div className='logo-conainer'>
            <img src={LogoDark} alt="logo" />
          </div>
          <div className='inner-container navigation-container'>
            <ul className='main-menu-items'>
              <li className='menu-item'><Link to="/">Home</Link></li>
              <li className='menu-item mega-menu-true'><Link to="/about-us">About Us</Link></li>
              <li className='menu-item mega-menu-true'><Link to="/services/">Our Services</Link></li>
              <li className='menu-item mega-menu-true'><Link to="/projects/">Our Case Studies</Link></li>
              <li className='menu-item'><Link to="/contact-us/">Contact Us</Link></li>
            </ul>
          </div>
          <div className='inner-container mobile-menu-icon'>
          <button className='mobile-menu-toggle' onClick={handleClick}>
            <SidebarLeft color='#1E1E1E' variant='TwoTone' />
          </button>
          </div>
          <div className='inner-container action-buttons'>
            <button id="PrimaryButton" to="tel:+355692225810">Call Us</button>
          </div>
        </div>
      </div>

      {/* OffCanvas Component */}
      <OffCanvas isActive={offCanvasActive} handleClose={handleClose} />
    </>
  );
}

export default PrimaryHeader;
