import React, { useState, useEffect } from "react";
import axios from "axios"; // If you are using Axios
import './ProjectListing.scss';

const ProjectListing = () => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch data from WordPress REST API
    const fetchProjects = async () => {
      try {
        const response = await axios.get("https://trusted-business-partners.com/wp-json/wp/v2/nproject");
        setProjects(response.data);
        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading projects: {error.message}</div>;
  }

  return (
    <div className="project-list">
      {projects.map((project) => (
        <div key={project.id} className="project-item">
          <h2 dangerouslySetInnerHTML={{ __html: project.title.rendered }}></h2>
          <p>Date: {new Date(project.date).toLocaleDateString()}</p>
          <a href={project.link} target="_blank" rel="noopener noreferrer">
            View Project
          </a>
        </div>
      ))}
    </div>
  );
};

export default ProjectListing;
